import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import {
  BsCloudUploadFill,
  BsFillFilePlayFill,
  BsFillFileEarmarkMusicFill,
  BsFillFileEarmarkPdfFill,
  BsFillFileEarmarkTextFill,
} from "react-icons/bs";
import { FcOk, FcSettings, FcHighPriority } from "react-icons/fc";
import { IoClose } from "react-icons/io5";
import labelsEn from "./translations/labels.en.json";
import labelsDe from "./translations/labels.de.json";
import logo from "./assets/ll-logo-en.png";
import errorImg from "./assets/angry.png";
import versionCheck from "./versionCheck";

const App = () => {
  versionCheck();

  const currentYear = new Date().getFullYear();

  const searchParams = new URLSearchParams(
    !!window ? window.location.search : ""
  );
  const { token, lang } = {
    token: searchParams.get("token"),
    lang: searchParams.get("lang") || "en",
  };

  const [files, setFiles] = useState([]);
  const [showRejected, setShowRejected] = useState(true);

  const getLabel = (labelId) => {
    let labels = {};
    switch (lang) {
      case "de":
        labels = labelsDe;
        break;
      default:
        labels = labelsEn;
        break;
    }
    return !!labels?.[labelId] ? labels?.[labelId] : labelId;
  };

  const callPrivateUpload = ({ token, file, onSuccess, onError }) => {
    const url = `https://files.api.llcrm.net/Upload/PrivateFileUpload`;
    axios({
      method: "POST",
      url: url,
      data: {
        TokenId: token,
        FileName: file.name,
      },
    })
      .then((res) => {
        if (res.status === 200 && !!res.data.redirectResult) {
          axios({
            method: "PUT",
            url: res.data.redirectResult,
            data: file,
            headers: {
              "content-type": "application/octet-stream",
            },
          })
            .then((uploadRes) => {
              !!onSuccess && onSuccess(uploadRes?.data);
            })
            .catch((error) => {
              if (!!error.response) {
                onError(error?.response?.data?.title);
              } else if (!!error.request) {
                onError();
              } else {
                onError(error?.message);
              }
            });
        } else {
          !!onError
            ? onError(res?.statusText)
            : console.log("Request failed", res);
        }
      })
      .catch((error) => {
        if (!!error.response) {
          onError(!!error.response?.data?.title);
        } else if (!!error.request) {
          onError();
        } else {
          onError(error?.message);
        }
      });
  };

  const onDrop = (acceptedFiles) => {
    setShowRejected(true);
    if (acceptedFiles && acceptedFiles.length > 0) {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            uploadInProgress: true,
            uploadFinished: false,
            fileId: null,
          })
        )
      );

      acceptedFiles.forEach((file) =>
        callPrivateUpload({
          token: token,
          file: file,
          onSuccess: (data) => {
            setFiles((currentFiles) =>
              currentFiles.map((item) =>
                item.path === file.path
                  ? Object.assign(file, {
                      uploadInProgress: false,
                      uploadFinished: true,
                    })
                  : item
              )
            );
          },
          onError: (error) => {
            setFiles((currentFiles) =>
              currentFiles.map((item) =>
                item.path === file.path
                  ? Object.assign(file, {
                      uploadInProgress: false,
                    })
                  : item
              )
            );
          },
        })
      );
    }
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept: {
        "image/*": [
          ".png",
          ".gif",
          ".jpeg",
          ".jpg",
          ".bmp",
          ".tiff",
          ".tif",
          ".psd",
          ".eps",
          ".indd",
          ".webp",
          ".svg",
          ".avif",
          ".ico",
        ],
        "application/vnd.amazon.ebook": [".azw"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "application/epub+zip": [".epub"],
        "application/vnd.ms-fontobject": [".eot"],
        "application/json": [".json"],
        "application/ld+json": [".jsonld"],
        "application/vnd.oasis.opendocument.presentation": [".odp"],
        "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
        "application/vnd.oasis.opendocument.text": [".odt"],
        "application/pdf": [".pdf", ".ai"],
        "application/vnd.ms-powerpoint": [".ppt"],
        "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          [".pptx"],
        "application/rtf": [".rtf"],
        "application/x-shockwave-flash": [".swf"],
        "application/vnd.visio": [".vsd"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
        "application/xml": [".xml"],
        "application/postscript": [".eps"],
        "text/*": [".xml", ".css", ".csv", ".txt"],
        "video/*": [".avi", ".mp4", ".mpeg", ".ogv", ".webm"],
        "audio/*": [
          ".aac",
          ".mp3",
          ".oga",
          ".opus",
          ".mid",
          ".midi",
          ".wav",
          ".weba",
        ],
        "font/otf": [".otf"],
        "font/ttf": [".ttf"],
        "font/woff": [".woff"],
        "font/woff2": [".woff2"],
      },
    });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  });

  return (
    <div className="main-wrap">
      <nav className="main-nav">
        <img alt="logo" src={logo} className="nav-logo" />
      </nav>
      <div className="center-wrap">
        <main className="upload-tool scale-in-center">
          {/* Invalid token */}
          {!token ? (
            <div className="message">
              <img src={errorImg} alt="Error" className="message-img" />
              <h4>{getLabel("URL-invalid-or-expired")}</h4>
              <p>
                {getLabel("Contact")}{" "}
                <a
                  target="_blank"
                  href="https://loyaltylab.nl/en/contactus/"
                  rel="noopener noreferrer"
                >
                  Loyalty Lab
                </a>{" "}
                {getLabel("for-help")}
              </p>
            </div>
          ) : null}

          {/* Dropzone */}
          {!!token ? (
            <div>
              <h1>{getLabel("Upload-your-files-here")}</h1>
              <p className="sub-title">
                <span className="hl">*</span>
                {getLabel("No")} .exe, .zip, .rar
              </p>
              <div {...getRootProps()} className="dropzone">
                <div>
                  <input {...getInputProps()} title="fileInput" />
                  {isDragActive ? (
                    <div className="drag-active">
                      <div className="icon-wrap">
                        <BsCloudUploadFill />
                      </div>
                      <p className="dropzone-label">
                        <span>{getLabel("Drop-files-here")}</span>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div className="icon-wrap">
                        <BsCloudUploadFill />
                      </div>
                      <p className="dropzone-label">
                        <span>{getLabel("Drag-and-drop")}</span>{" "}
                        <span>{getLabel("or-click-to-select")}</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          {/* Preview   */}
          {!!files.length ? (
            <div className="preview-wrap">
              {files.map((file) => (
                <div
                  key={file.path}
                  className="preview-block-outer"
                  title={file.path}
                >
                  <div className="preview-block">
                    {file.type.indexOf("image/") > -1 ? (
                      <img
                        src={file.preview}
                        alt={`Uploaded ${file.path}`}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                          URL.revokeObjectURL(file.preview);
                        }}
                      />
                    ) : file.type.indexOf("video/") > -1 ? (
                      <BsFillFilePlayFill className="preview-icon" />
                    ) : file.type.indexOf("application/pdf") > -1 ? (
                      <BsFillFileEarmarkPdfFill className="preview-icon" />
                    ) : file.type.indexOf("audio/") > -1 ? (
                      <BsFillFileEarmarkMusicFill className="preview-icon" />
                    ) : (
                      <BsFillFileEarmarkTextFill className="preview-icon" />
                    )}
                    <div className="overlay-icon">
                      {file.uploadInProgress && !file.uploadFinished ? (
                        <span className="overlay-icon-block">
                          <FcSettings className="spin" />
                        </span>
                      ) : file.uploadFinished ? (
                        <span className="overlay-icon-block">
                          <FcOk className="scale-in-center" />
                        </span>
                      ) : (
                        <span className="overlay-icon-block">
                          <FcHighPriority className="scale-in-center" />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}

          {!token ? null : !!files.length &&
            !!files.filter(
              (file) => !!file.uploadFinished && !file.uploadInProgress
            ).length ? (
            <div
              className="message margin success clickable"
              onClick={() => setFiles([])}
            >
              {/* Upload successful */}
              <h4>
                <FcOk className="inline-icon" />
                {getLabel("Files-uploaded")} (
                {
                  files.filter(
                    (file) => !!file.uploadFinished && !file.uploadInProgress
                  ).length
                }
                )
              </h4>
              <p>
                {files
                  .filter(
                    (file) => !!file.uploadFinished && !file.uploadInProgress
                  )
                  .map((file, index) => (
                    <span key={file.path}>
                      {file.path}
                      {index !== files.length - 1 ? ", " : ""}
                    </span>
                  ))}
              </p>
              <button
                className="close-btn"
                onClick={() => setFiles([])}
                title="Close"
              >
                <IoClose />
              </button>
            </div>
          ) : !!files.length &&
            !!files.filter(
              (file) => !file.uploadFinished && !file.uploadInProgress
            ).length ? (
            <div
              className="message margin error clickable"
              onClick={() => setFiles([])}
            >
              {/* Upload failed */}
              <h4>
                <FcHighPriority className="inline-icon" />
                {getLabel("Failed-to-upload")} (
                {
                  files.filter(
                    (file) => !file.uploadFinished && !file.uploadInProgress
                  ).length
                }
                )
              </h4>
              <p>
                {files
                  .filter(
                    (file) => !file.uploadFinished && !file.uploadInProgress
                  )
                  .map((file, index) => (
                    <span key={file.path}>
                      {file.path}
                      {index !== files.length - 1 ? ", " : ""}
                    </span>
                  ))}
              </p>
              <button
                className="close-btn"
                onClick={() => setFiles([])}
                title="Close"
              >
                <IoClose />
              </button>
            </div>
          ) : null}

          {/* Rejected files */}
          {!!fileRejections.length && !!showRejected ? (
            <div
              className="message margin error clickable"
              onClick={() => setShowRejected(false)}
            >
              <h4>
                <FcHighPriority className="inline-icon" />
                {getLabel("Files-rejected")} ({fileRejections.length})
              </h4>
              <p>
                {fileRejections.map((file, index) => (
                  <span key={file.file.path}>
                    {file.file.path}
                    {index !== fileRejections.length - 1 ? ", " : ""}
                  </span>
                ))}
              </p>
              <button
                className="close-btn"
                onClick={() => setShowRejected(false)}
                title="Close"
              >
                <IoClose />
              </button>
            </div>
          ) : null}
        </main>
        <footer>
          &copy; {currentYear} {getLabel("Powered-by")} Loyalty Lab
        </footer>
      </div>
    </div>
  );
};

export default App;
